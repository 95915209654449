import React, { useState, useContext } from 'react';
import { Document, pdfjs} from 'react-pdf';
import VerFirmas from  './VerFirmas'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./PDFReader.css";
// import PDFFile from '../Acta Prueba.pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function PDFReader(props){
  const [numPages, setNumPages] = useState(null);
  const { name:nameDocumento, base64 } = props.docData;
  // console.log("Ver Firmas:",props.verFirmas);
  function onDocumentLoadSuccess(pdf) {
    // console.log(pdf);
    setNumPages(pdf.numPages);
  }

  return (
    <div>
      <h3 className={'h3 pb-3'}>Documento: {nameDocumento}</h3>
      <Document renderMode='canvas' file={`data:application/pdf;base64,${base64}`} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
              <VerFirmas key={'VerFirmas-'+(index+1)} pageScale={props.scale} numPages={numPages} pageNumber = {index+1} verFirmas={props.verFirmas} valueFirmantes={props.arrayFirmantes}/>
          ))}
      </Document>

    </div>
  );
}
export default PDFReader;