import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import StepDot from  './StepDot'

function ModalStepper({ maxStep, currentStep }){

    return(
        <div>
            {Array.from(new Array(maxStep), (el, index) => (
                
                <StepDot key={'dot-'+index+1} index={index+1} currentStep={currentStep} maxStep={maxStep}/>
            ))}
        </div>
        
    )
}

export default ModalStepper;