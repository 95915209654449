import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./StepDot.css";

function StepDot({ index, currentStep, maxStep }){

    // console.log("Key of dot:",index);

    const [dotState, setDotState] = useState(null)

    useEffect(() => {
        // console.log(maxStep);
        // console.log("Is it blank",maxStep < 3);
        var state = maxStep  < 3 ? 'blank': 
            currentStep === index ? 'current' :
            currentStep > index ? 'past' :
            'new'
        setDotState(state)
    }, [currentStep, maxStep]);
    


    return(
        <span id={`dot-step-${index}`} className={`dot dot-${dotState} me-1`}></span>
    )
}

export default StepDot;