import React from "react";
import BitSignDesk from "./components/BitSignDesk";
import BigPrimeDesk from "./components/BigPrimeDesk";
import PrivacyPolicy from "./components/PrivacyPolicy";
import App from "./components/App";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const Home = () => {

    return(
        <BrowserRouter>
            <Routes>
                <Route path='/BitSignDesk' element={<BitSignDesk/>}></Route>
                <Route path='/BigPrimeDesk' element={<BigPrimeDesk/>}></Route>
                <Route path='/PrivacyPolicy' element={<PrivacyPolicy/>}></Route>
                <Route path='/' element={<App/>}></Route>
            </Routes>
        </BrowserRouter>
    )
}
export default Home