//@ts-check

import React, {useRef, useState, useEffect} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReCAPTCHA from 'react-google-recaptcha';
import Validator from './Validator';
import APIRequest from './APIRequest';
import { Button, ButtonGroup, Form, Image } from 'react-bootstrap';

const App = () => {

  //const [Params, CambiarParams] = useState(false);
  const [bsParam, setbsParam] = useState("");
  const [bsParams, setbsParams] = useState({
    bsCode: "",
    bsPass: ""
  });
  const [captchaValido, CambiarCaptchaValido] = useState(null);
  const [usuarioValido, CambiarUsuarioValido] = useState(false);
  const [signer, CambiarSigner] = useState(false);
  const [validator, CambiarValidator] = useState(false);
  const [tipoServicio, CambiarTipoServicio] = useState("Firmar");

  const captcha = useRef(null);
  const myForm = useRef(null);
  const onChange = () => {
    //console.log("get val", captcha.current.getValue());
    if(captcha.current.getValue()){
      //console.log("no es robot");
      CambiarCaptchaValido(true);
    };
  }
  const onSubmit = (e) => {
    e.preventDefault();
    
    let bsParamsCurrent = {
      bsCode: "",
      bsPass: ""
    };

    if(captcha.current.getValue()){
      //console.log("no es robot");
      CambiarUsuarioValido(() => true);
      CambiarCaptchaValido(() => true);
      myForm.current.buttonId === "validator"
      ? bsParamsCurrent = {
          bsCode: bsParam,
          bsPass: ""
        }
      : bsParamsCurrent = {
          bsCode: bsParam,
          bsPass: e.target.bitsigncode.value
        };
    }else{
      CambiarUsuarioValido(() => false);
      CambiarCaptchaValido(() => false);
    }
    setbsParams(bsParamsCurrent);
    //console.log("var",bsParams);
    myForm.current.buttonId === "validator" ? CambiarValidator(() => true) : CambiarSigner(() => true) ;
  }

  useEffect(() => {
    const bstoken = window.location.search.split('?bstoken=')[1];
    //console.log("queryString", bstoken);
    if(bstoken != null){
      setbsParam(bstoken)
      if(bstoken.length < 30){
        document.getElementById("bitsigncode").setAttribute('value', bstoken);
        document.getElementById("bitsigncode").setAttribute('disabled', 'disabled');
        CambiarTipoServicio("Validar");
      }
    }
  },[])
  //const dataBS = {idProceso:2308, codeCliente:"BIT365", emailUsuario:"usuario.ecommerce@grupobusiness.it"};

  return (
    <div className="App">
      { !usuarioValido &&
        <div className="init">
          <Image src="https://azfstorage593.z13.web.core.windows.net/BitSignLogo.png" className="logo" />
          <p>Código de acceso:</p>
          <Form className='initForm' action="" onSubmit={onSubmit} ref={myForm}>
            <Form.Control type="text" name="bitsigncode" id="bitsigncode" placeholder="bitsign-code" required/>
            <div className='recaptcha'>
                <ReCAPTCHA
                  ref={captcha}
                  sitekey='6LdxERYeAAAAAH6jjwpwHrLVr7HDd4-EodXmAqi2'
                  onChange={onChange}
                />
            </div>
            {captchaValido === false && <div className='error-captcha'>Este campo es requerido.</div>}
            <ButtonGroup className='buttongroup'>
              {tipoServicio === "Firmar" && <Button className="btn btn-dark" type="submit" id='signer' onClick={e => myForm.current.buttonId=e.target.id} title="Firma un documento como externo."  style={{borderRadius: 4}}>Firmar Proceso</Button>}
              {tipoServicio === "Validar" && <Button className="btn btn-dark" type="submit" id='validator' onClick={e => myForm.current.buttonId=e.target.id} title="Valida una firma ya efectuada."  style={{borderRadius: 4}}>Validar Firma</Button>}
            </ButtonGroup>
          </Form>
          <a href='/PrivacyPolicy'>Política de Privacidad</a>
        </div>
      }
      { (usuarioValido && validator) &&
        <div>
          <Validator bs={bsParams}/>
        </div>
      }
      { (usuarioValido && signer) &&
        <div id='signex'>
          <APIRequest bs={bsParams}/>
        </div>
      }
    </div>
  );
}

export default App;
