import React, { useState, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Stack, Alert } from 'react-bootstrap';
import ModalStepper from  './ModalStepper';
import StepContent from  './StepContent';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
// import { ProcesoContext } from './APIRequest';
// import { useParams } from 'react-router-dom';
import { FileContext } from './FirmadoExterno';
import { ParamsContext } from './APIRequest';
import "./FirmarModal.css";
import $ from 'jquery';
import axios from 'axios';

function FirmarModal({ processUser, elementosFirma, onFinishProcess, showModal, onHide, estadoProceso, lastUser, docData, value }){
  const [maxStep,setMaxStep] = useState(null);
  const [currentStep,setCurrentStep] = useState(1);
  const [stateFirma,setStateFirma] = useState(null);
  const [typeFirma,setTypeFirma] =useState('Digital')
  const [canvasFirma,setCanvasFirma] = useState(null);
  const [canvasSumilla,setCanvasSumilla] = useState(null);
  const [dataURLFirma,setDataFirma] = useState(null);
  const [dataURLSumilla,setDataSumilla] = useState(null);
  const [loadingState,setLoadingState] = useState(true);
  const [loadedState,setLoadedState] = useState(null);
  const [enableNextButton,setEnableNextButton] = useState(false);
  const [show, setShow] = useState(false);

  const fileData = docData;
  const procesoData = value;
  // console.log("d",processUser);
  // const arrayFirmantes = procesoData.firmantes
  const currentUser = processUser;
  const sumillaCheck = currentUser.sumilla.isChecked;
  const { nameProceso: nombreProceso, firmantes: arrayFirmantes, validator } = procesoData;
  const { idFirmantesProceso } = currentUser;
  // console.log("idFirmantesProceso:",idFirmantesProceso);

  const { codeCliente, emailUsuario, idProceso } = useContext(ParamsContext);
  const sumillaInferior = codeCliente === 63;
  // console.log(codeCliente, emailUsuario, idProceso);
  useEffect(() => {
    // console.log(currentUser);
    setMaxStep(sumillaCheck && currentUser.tipoFirma!=='Sin Firma' ? 3 : 2);
  }, [currentUser,sumillaCheck])
  
  useEffect(() => {
    // console.log(currentUser);
      // if (currentUser.tipoFirma === 'Criptográfica'){
      //   setStateFirma('Cripto');
      // }
      // else{
      if (currentUser.tipoFirma === 'Sin Firma') {
        if (currentStep === 1)
          setStateFirma('Sumilla');
        else
          setStateFirma('Final');
      }
      else {
        if (sumillaCheck) {
          if (currentStep === 1)
            setStateFirma('Firma');
          else {
            if (currentStep === 2 )
              setStateFirma('Sumilla');
            else
              setStateFirma('Final');
            }
        }
        else {
          if (currentStep === 1)
            setStateFirma('Firma');
          else
            setStateFirma('Final');
        }
        // }
      }
  }, [currentStep,currentUser,sumillaCheck])

  function updateImagenFirma(imageData){
    // console.log(imageData);
    setDataFirma(imageData)
  }

  function updateImagenSumilla(imageData){
    setDataSumilla(imageData)
  }
  async function nextStepper(){
    var blankCanvas = await isCanvasBlank(stateFirma==="Firma"?canvasFirma:canvasSumilla, stateFirma==="Firma"?"nextStepper, firma":"nextStepper, sumilla")
    // console.log(blankCanvas);
    // if((stateFirma==="Firma" ? isCanvasBlank(canvasFirma, "nextStepper, firma") : stateFirma==="Sumilla" ? isCanvasBlank(canvasSumilla,"nextStepper, sumilla") : false)){
    if(blankCanvas){
    // alert("Canvas en blanco");
      setShow(true)
      // setTimeout(() => {
      //   setShow(false)
      // }, 5000);
      console.warn("Canvas en blanco");
    }
    else {
      setShow(false);
      if (stateFirma==="Firma") {
        // updateImagenFirma(canvasFirma.toDataURL('image/png').replace('data:image/png;base64,', ''));
        // console.log("Data firma: ",dataURLFirma);
      }
      else if (stateFirma==="Sumilla") {
        updateImagenSumilla(canvasSumilla.toDataURL('image/png').replace('data:image/png;base64,', '')) 
        // console.log("Data sumilla:",dataURLSumilla);
      }
      else {
        console.log('Firmando...')
      }
      setCurrentStep(currentStep+1)
      // console.log("Current step:",currentStep);
      // console.log("Max step:",maxStep);
      if(currentStep === maxStep - 1)
        firmarDocumento(false);
    }  
  }
  function previousStepper(){
    setShow(false)
    if(currentStep > 1)
      setCurrentStep(currentStep-1)
  }
  useEffect(()=>{
    // console.log("Last user:",lastUser);
  },[lastUser]);
  function assignCanvasFirma(canvas){
    setCanvasFirma(canvas)
    // console.log("Canvas firma:",canvas);
  }

  function assignCanvasSumilla(canvas){
    setCanvasSumilla(canvas)
    // console.log("Canvas sumilla:",canvas);
  }

  function isCanvasBlank(canvas, callingFunction) {
    // console.log("Canvas en blanco?",canvas);
    // console.log(callingFunction);
    // console.log(stateFirma);
    // const context = canvas.getContext('2d',{willReadFrequently:true});
  
    // return !(new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer).some(color => color !== 0));
    var promise = new Promise((resolve,reject)=>{
      const blank = document.createElement('canvas');

      blank.width = canvas.width;
      blank.height = canvas.height;
  
      resolve(canvas.toDataURL() === blank.toDataURL());
      
      // const context = canvas.getContext('2d',{willReadFrequently:true});
    
      // resolve( !(new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer).some(color => color !== 0)));

    });

    return promise;

    // return !pixelBuffer;
  }
  
  const base64Arraybuffer = async (data) => {
    const base64url = await new Promise((r) => {
      const reader = new FileReader();
      reader.onload = () => r(reader.result);
      reader.readAsDataURL(new Blob([data]));
    });
    return base64url.split(",", 2)[1];
  };
  
  // useState(()=>{
  //   console.log("Cargando:",loadingState);
  //   console.log("Exitoso:",loadedState);

  // },[loadingState,loadedState])


  function postSignedDocument (docBase64, callback, errorCallback){
    setLoadingState(true);
    var postObject = {
      "idFirmantesProceso": idFirmantesProceso,
      "lastFirmante": lastUser,
      "codeCliente": codeCliente,
      "emailUsuario": emailUsuario,
      "nameDocumento": fileData.name,
      "base64Documento": docBase64
    };
    // console.log("Posting data:",postObject);
    axios.post('https://bitsign.azurewebsites.net/api/BitSignIndependent/PostSignDocument',postObject)
    .then( response => callback(response) )
    .catch(error => errorCallback(error))
  }

  async function firmarDocumento(testDownload=false){

    // if(isCanvasBlank(canvasFirma) || (isCanvasBlank(canvasSumilla) && sumillaCheck))
    //   alert("Canvas en blanco")
    // else{
      // console.log(idProceso);
    if(validator){
      var newBuffer
      var jsonValidator={
        "idProceso": idProceso,
        "paqueteCliente": codeCliente,
        "emailUsuario": emailUsuario,
        }
        // console.log(jsonValidator);
      const qrService = await axios.post("https://bitsign.azurewebsites.net/api/BitSignValidator/PostQrGenerator",jsonValidator);
      newBuffer = await embedImages(qrService.data.base64ImagenQr);

      // console.log(qrService);
    }
    else{
      newBuffer = await embedImages();
    }
    var docBase64 = await base64Arraybuffer(newBuffer);

    // // Para poder descargar
    // const linkSource = `data:application/pdf;base64,${docBase64}`;
    // const downloadLink = document.createElement("a");
    // const fileName = "abc.pdf";
    // downloadLink.href = linkSource;
    // downloadLink.download = fileName;
    // downloadLink.click();

    if(testDownload){
      const linkSource = `data:application/pdf;base64,${docBase64}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = `${nombreProceso} - ${new Date().toLocaleString("es-US", { year: "numeric", month: '2-digit', day: '2-digit'})}`;
      downloadLink.click();
    }
    else{
      postSignedDocument(docBase64, async (response) => {  
        setLoadingState(false);

        var validated = await response.data.validated
        setLoadedState(validated);
        onFinishProcess(validated,docBase64)

        console.log("Post document response: ",response)
      },
      (error) => {
        setLoadingState(false);
        setLoadedState(false);
        console.log("Error:",error);
      });
    }
  };

  function _base64ToArrayBuffer(base64) {
    //console.log("base64",base64);
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  /*----------------------------*/
  function transformPoint(x,y,degree,height, width){
    var quadrant = (degree / 90) % 4;

    if(quadrant === 3 || quadrant === 2)
        x = -x + width;
    if(quadrant === 2 || quadrant === 1)
        y = -y + height;
    if(quadrant % 2 === 1)
        [x,y]=[y,x]

    return {x,y};
  }

  async function embedImages(qrImage64 = "")
  {
    // console.log(qrImage64);
    var today = new Date().toLocaleString("es-US",{weekday:"short",day:"2-digit",month:"2-digit",year:"numeric"});
    var nombreIniciales = currentUser.nombreFirmante.split(' ').reduce((accumulator,currentValue) =>{
        return accumulator + currentValue.charAt(0)+'. ';
    },'');
    // console.log("today",today);
   // var fechaCorta=today.split("T")[0];
    //console.log("todayT",fechaCorta);
    // $("div#emailwidth")[0].innerHTML=emailUser;
    $("div#fechaActualwidth")[0].innerHTML=today;
    $("div#nombrewidth")[0].innerHTML=currentUser.nombreFirmante;
    $("div#inicialeswidth")[0].innerHTML=nombreIniciales;
    // var objemailwidth = document.getElementById("emailwidth");
    var objfechaActualwidth = document.getElementById("fechaActualwidth");
    var objnombrewidth = document.getElementById("nombrewidth");
    var objinicialeswidth = document.getElementById("inicialeswidth");
    // var emailwidth= parseInt(objemailwidth.clientWidth);
    var fechaActualwidth= parseInt(objfechaActualwidth.clientWidth);
    var nombrewidth= parseInt(objnombrewidth.clientWidth);
    var inicialeswidth= parseInt(objinicialeswidth.clientWidth);
    // const { PDFDocument,StandardFonts,degrees } = PDFLib;   
    // console.log("Iniciales:",nombreIniciales);
    
    // var canvas = document.getElementById(canvasFirma);
    // var ctx = canvas.getContext('2d');
    // var img = new Image();

    // const existingPdfBytes = await fetch('/assets/docs/Acta Prueba.pdf').then(res => res.arrayBuffer());
    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(fileData.base64);
    
    var Imagen64 = (typeFirma==='Texto'? dataURLFirma : canvasFirma.toDataURL('image/png')).replace('data:image/png;base64,', '');
    const pngImageBytes = _base64ToArrayBuffer(Imagen64);
    const pngImage = await pdfDoc.embedPng(pngImageBytes);
    const qrImageBytes = validator && qrImage64 !== "" ? _base64ToArrayBuffer(qrImage64) : null;
    const pngImageQr = validator && qrImage64 !== "" ? await pdfDoc.embedPng(qrImageBytes) : null;
    var Imagen64Sum = "";
    var pngImageBytesSum = "";
    var pngImageSum = "";
    if(sumillaCheck) {
      Imagen64Sum = dataURLSumilla;
      pngImageBytesSum = _base64ToArrayBuffer(Imagen64Sum);
      pngImageSum = await pdfDoc.embedPng(pngImageBytesSum);
    }
    const pngDims = pngImage.scale(0.35);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const pages = pdfDoc.getPages();
    // var image = new Image();
    // image.src = "data:image/jpg;base64," + Imagen64;

    // var w = window.open("");
    // w.document.write(image.outerHTML);
    // console.log("NumHoja",NumHoja);
    // console.log("today",today);
    // console.log("imagen png",pngImage);

    // console.log(pngDims);
    var heightImagen=pngDims.height ;
    var widthImagen=pngDims.width;

    if(currentUser.tipoFirma !== 'Sin Firma'){
        for(var i=0; i<currentUser.firmas.length;i++){
            if(currentUser.tipoFirmado === 'Dinamico')
            {
                //No hoja anexo
                heightImagen=parseInt(currentUser.firmas[i].alto);
                widthImagen=parseInt(currentUser.firmas[i].ancho);
            }
            var numH = parseInt(currentUser.firmas[i].numeroHoja-1);
            var PosXQR = currentUser.firmas[i].x;
            var PosX = validator ? currentUser.firmas[i].x + heightImagen : currentUser.firmas[i].x;
            var PosY = currentUser.firmas[i].y;
            // const firstPage = currentUser.TipoFirmado === 'Anexo' ? pages[pages.length-1] : pages[numH];
            const signingPage = pages[numH];
            // (numfirmantes < 7 ? pages[pages.length-1] : pages[pages.length-2] )
            
            // console.log("firstPage()",firstPage);
            var { width, height } = signingPage.getSize();

            //En caso de que la pagina tenga una rotacion
            var pageRotation = signingPage.getRotation().angle;
            if (((pageRotation / 90) % 4) % 2 === 1)
                [width,height] = [height,width];
            signingPage.setRotation(degrees(0))
            // console.log("Rotacion:",pageRotation);
            // console.log("height documento",height);
            // console.log("width documento",width);

            //El height comienza a medirse desde el fondo de la pagina
            PosY=height-(parseInt(PosY)+parseInt(heightImagen)-1);
            // console.log("PosY",PosY);
            // console.log("PosX",PosX);

            // Calcular puntos de acuerdo a la rotacion de la pagina
            var newPoint = transformPoint(PosX, PosY, pageRotation, height, width)
            var newPointQR = transformPoint(PosXQR, PosY, pageRotation, height, width);
            var newPointX = newPoint.x
            var newPointY = newPoint.y
            var newPointName=transformPoint(parseFloat(PosXQR)+(widthImagen + (validator && currentUser.tipoFirmado === 'Dinamico' ?0 : heightImagen) - nombrewidth)/2, parseFloat(PosY)-1, pageRotation, height, width);
            var newPointDate=transformPoint(parseFloat(PosXQR)+(widthImagen + (validator && currentUser.tipoFirmado === 'Dinamico' ?0 : heightImagen) - fechaActualwidth)/2, parseFloat(PosY)-6, pageRotation, height, width);
            if(validator && qrImage64 !== ""){
              signingPage.drawImage(pngImageQr, {
                x: parseFloat(newPointQR.x),
                y: parseFloat(newPointQR.y),
                width: heightImagen,
                height: heightImagen,
                rotate: degrees(pageRotation),
              });
            }
            signingPage.drawImage(pngImage, {
                x: parseFloat(newPointX),
                y: parseFloat(newPointY),
                width: validator && currentUser.tipoFirmado === 'Dinamico' ? widthImagen-heightImagen : widthImagen,
                height: heightImagen,
                rotate: degrees(pageRotation), 
            });
            if(elementosFirma.nombre)
            signingPage.drawText(currentUser.nombreFirmante, {
                x: newPointName.x,
                y: newPointName.y,
                size: 6,
                font: helveticaFont,
                rotate: degrees(pageRotation),       
            });
            if(elementosFirma.fecha)
            signingPage.drawText(today, {
                x: newPointDate.x,
                y: newPointDate.y,
                size: 6,
                font: helveticaFont,
                rotate: degrees(pageRotation), 
            });
            signingPage.setRotation(degrees(pageRotation));

        }
    }

    if(sumillaCheck || currentUser.tipoFirma === 'Sin Firma'){
    var longSide = sumillaInferior ? 52:60;
    var shortSide = sumillaInferior ? 40:50;
    pages.forEach((element, index) => {
          var pageRotationSum = element.getRotation().angle;
          var { width, height } = element.getSize();
          if (((pageRotationSum / 90) % 4) % 2 === 1)
              [width,height] = [height,width];
          var newSumilla = {
            x: sumillaInferior ? (width - longSide * (currentUser.sumilla.position + 1)*1.5 - longSide) : (width - longSide - 3),
            y: sumillaInferior ? 10 : (longSide * (currentUser.sumilla.total - currentUser.sumilla.position)*1.5 + longSide + 5),

            // x: width - longSide - 3,
            // y: longSide * (currentUser.sumilla.total - currentUser.sumilla.position)*1.5 + longSide + 5,
            width: longSide,
            height: shortSide, 
          }
          element.setRotation(degrees(0))
          var newPointSum = transformPoint(newSumilla.x, newSumilla.y, pageRotationSum, height, width)
          var newPointSumX = newPointSum.x
          var newPointSumY = newPointSum.y
          var newPointInit=transformPoint(parseFloat(newSumilla.x)+(newSumilla.width-inicialeswidth)/2, parseFloat(newSumilla.y)-1, pageRotationSum, height, width);
          // console.log(index, pages.length-1)
          if(currentUser.tipoFirmado === "Anexo" && index === pages.length-1)
              console.log("Ultima pagina anexo no se sumilla.")
          else{
              element.drawImage(pngImageSum, {
                  x: parseFloat(newPointSumX),
                  y: parseFloat(newPointSumY),
                  width: parseFloat(newSumilla.width),
                  height: parseFloat(newSumilla.height),
                  rotate: degrees(pageRotationSum),       
              });
              element.drawText(nombreIniciales, {
                  x: newPointInit.x,
                  y: newPointInit.y,
                  size: 6,
                  font: helveticaFont,
                  rotate: degrees(pageRotationSum),           
              });    
          }
          element.setRotation(degrees(pageRotationSum));
      });
  }


    const pdfBytes = await pdfDoc.save();

    return pdfBytes;
}

function resetModal() {
  if (stateFirma !== 'Final'){
    setCurrentStep(1);
  }
}

function onErrorFunction(){
  setCurrentStep(1);
  setLoadingState(true);
  setLoadedState(null);
}

return (
    <>    
      <Modal show={showModal} onExited={resetModal} onHide={()=>onHide(onErrorFunction)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Firmar {`${nombreProceso}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StepContent onCanvasChange={assignCanvasFirma} onCanvasSumChange={assignCanvasSumilla} setTypeFirma={setTypeFirma} updateImagenFirma={updateImagenFirma} updateImagenSumilla={updateImagenSumilla} enableNext={setEnableNextButton} stateFirma={stateFirma} loadingState={loadingState} loadedState={loadedState}/>
          {show && <br/>}
          <Alert variant="danger" show={show} onClose={() => setShow(false)} dismissible>
            <h6>
              <b> {stateFirma === 'Firma' ? 'FIRMA':'SUMILLA'} EN BLANCO</b> | La {stateFirma === 'Firma' ? 'firma':'sumilla'} es obligatoria.
            </h6>
          </Alert>
                  </Modal.Body>
        {estadoProceso === 'En Proceso' && <Modal.Footer style={{display:"unset"}}>
            <Stack direction="horizontal" gap={3}>
            { 
              <Button className="me-auto modal-button" variant="secondary" disabled={currentStep >= maxStep} onClick={onHide}>
                Cancelar
              </Button>
            }
            <Button className="modal-button" variant="secondary" disabled={currentStep <= 1} onClick={previousStepper} style={{display:maxStep !== 3 ? 'none' : ''}}>
              {'Atrás'}
            </Button>
            
            <ModalStepper currentStep={currentStep} maxStep={maxStep}/>
            <Button className="modal-button" variant="primary" disabled={!enableNextButton || currentStep >= maxStep} onClick={nextStepper}>
              {currentStep === maxStep ? 
                'Finalizar' :
                maxStep === 3 ? 
                  stateFirma === 'Sumilla' ? 
                    'Firmar' : 
                    'Siguiente' : 
                  stateFirma+'r'
                  }
            </Button>
          </Stack>
        </Modal.Footer>}
      </Modal>
    </>
  );
}

export default FirmarModal;