import React from "react";
import './BitSignDesk.css';

const PrivacyPolicy = () => {

    return (
      <div className="BigPrimeDesk">
        <div className="policy">
          <h1>POLÍTICA DE PRIVACIDAD DEL SERVICIO DE FIRMAS BITSIGN</h1>
          <h2>
            1. <strong>Introducción y Responsabilidades</strong>:
          </h2>
          <ul>
            <li>
              <strong>Servicio</strong>: BITSIGN (desde ahora referido como el
              SERVICIO) es un sistema de firmado de documentos PDF integrado en
              un ambiente de M365, alojado en SharePoint. Cuenta con un servicio
              externo para firmantes que no pertenecen al ambiente en el cual se
              integra, referidos como firmantes externos.
            </li>
            <li>
              <strong>Empresa</strong>: GRUPO BUSINESS IT (desde ahora referida
              como la EMPRESA) es la entidad creadora y distribuidora del
              servicio de firmas BITSIGN.
            </li>
            <li>
              <strong>Cliente</strong>: La entidad que contrata el SERVICIO para
              ser desplegado en su ambiente de SharePoint de M365 por medio de
              una suscripción de un número definido de transacciones.
            </li>
          </ul>

          <h2>
            2. <strong>Recopilación de Datos</strong>:
          </h2>
          <ol>
            <li>
              <strong>Datos del Cliente</strong>:
            </li>
            <ul>
              <li>
                i. La URL del sitio de SharePoint donde se
                aloja el SERVICIO en el ambiente del CLIENTE.
              </li>
              <li>
                  ii. Los siguientes metadatos del proceso de firma generado por
                  el CLIENTE
                :
                <ol>
                  <li>El nombre del proceso.</li>
                  <li>
                    El nombre y correo del usuario gestor, según el ambiente del
                    cliente.
                  </li>
                </ol>
              </li>
              <li>
                  iii. Los siguientes metadatos del firmado de un firmante
                  definido por el CLIENTE
                , si este acepta el uso del sistema de validación de firma por
                código QR:
                <ol>
                  <li>
                    El correo del usuario firmante, según definido por el gestor
                    del proceso.
                  </li>
                  <li>
                    El ID del proceso al cual se referencia, almacenado en el
                    SharePoint del CLIENTE.
                  </li>
                  <li>
                    Un URL de validación de la firma para acceder a la
                    información de esta firma.
                  </li>
                </ol>
              </li>
            </ul>
            <li>
              <strong>Archivos PDF</strong>: La EMPRESA no almacena el
              archivo generado para un proceso de firma por un usuario del
              CLIENTE.
            </li>
            <li>
              <strong>Metadatos de Firmado</strong>: Aparte de los metadatos
              mencionados anteriormente en el punto a subsección II, la EMPRESA
              no almacena los metadatos pertenecientes al proceso de firma,
              incluyendo, pero no limitado a, la posición de firma de cada
              firmante. Estos metadatos solo pueden ser visualizados por los
              participantes del proceso de firmas, el gestor de dicho proceso, y
              las personas con acceso a las cuentas administrativas del sistema.
            </li>
            <li>
              <strong>Imágenes de Firma</strong>: El SERVICIO puede almacenar
              en el SharePoint del ambiente del CLIENTE una imagen de firma
              predeterminada por parte del firmante, dado su consentimiento. El
              SERVICIO no almacena esta imagen en ninguna base de datos que sea
              propiedad de la EMPRESA.
            </li>
          </ol>

          <h2>
            3. <strong>Almacenamiento y Acceso a Datos</strong>:
          </h2>
          <ul>
            <li>
              <strong>Acceso a Imágenes de Firma</strong>: La EMPRESA no tiene
              acceso a, ni almacena ninguna imagen de firma proveída por el
              usuario firmante en ningún momento. El usuario firmante da
              consentimiento de almacenar en el ambiente del CLIENTE su imagen
              de firma para ser usada de manera predeterminada.
            </li>
            <li>
              <strong>Base de Datos de la Empresa</strong>:
                  La URL del sitio de SharePoint donde se
                  aloja el SERVICIO en el ambiente del CLIENTE.
            </li>
            <li>
              <strong>Metadatos del Proceso</strong>:
              <ul>
                <li>
                  Los siguientes metadatos del proceso de firma generado por el
                  CLIENTE:
                  <ol>
                    <li>El nombre del proceso.</li>
                    <li>
                      El nombre y correo del usuario gestor, según el ambiente
                      del cliente.
                    </li>
                  </ol>
                </li>
                <li>
                  Los siguientes metadatos del firmado de un firmante definido
                  por el CLIENTE, si este acepta el uso del sistema de
                  validación de firma por código QR:
                  <ol>
                    <li>
                      El correo del usuario firmante, según definido por el
                      gestor del proceso.
                    </li>
                    <li>
                      El ID del proceso al cual se referencia, almacenado en el
                      SharePoint del CLIENTE.
                    </li>
                    <li>
                      Un URL de validación de la firma para acceder a la
                      información de esta firma.
                    </li>
                  </ol>
                </li>
              </ul>
            </li>
          </ul>

          <h2>
            4. <strong>Plataforma de Firma Externa</strong>:
          </h2>
          <ul>
            <li>
              <strong>Aplicativo Web para Firmantes Externos</strong>: El
              aplicativo de firmas externa es una extensión del SERVICIO, el
              cual permite a usuarios que no pertenecen al ambiente de M365 del
              CLIENTE utilizar el SERVICIO para un proceso de firmas al cual
              haya sido asignado con su correo personal. Este servicio solo es
              utilizado si el CLIENTE desea permitir a usuarios externos a su
              organización firmar los procesos de firma creados en su ambiente.
            </li>
            <li>
              <strong>Llamada de Datos</strong>: Si el CLIENTE utiliza el
              servicio para firmantes externos, los datos del archivo son
              enviados a través de un servicio HTTP desde el ambiente de
              SharePoint del CLIENTE al aplicativo web, y viceversa, mas no se
              almacena esta información.
            </li>
            <li>
              <strong>No Almacenamiento de Archivos o Firmas Externas</strong>:
              El aplicativo externo del SERVICIO no almacena imágenes ni
              archivos en su uso.
            </li>
          </ul>

          <h2>
            5. <strong>Derechos de los Usuarios</strong>:
          </h2>
          <ul>
            <li>
              El CLIENTE tiene derecho a utilizar el SERVICIO dentro de la
              duración del contrato, o hasta que la cantidad de procesos
              contratadas se haya agotado, según el que ocurra primero.
            </li>
            <li>
              El CLIENTE mantiene el derecho y acceso a sus documentos, archivos
              y metadatos según las regulaciones de SharePoint Online. La
              EMPRESA no se hace responsable de la pérdida de datos una vez haya
              finalizado el término del contrato.
            </li>
            <li>
              La EMPRESA reserva el derecho de mantener los datos del cliente en
              su base de datos para facilidad de recontratación, salvo que lo
              indique expresamente el CLIENTE.
            </li>
          </ul>

          <h2>
            6. <strong>Consentimiento</strong>:
          </h2>
          <ul>
            <li>
              Los usuarios del CLIENTE dan su expreso consentimiento de
              almacenar la imagen de su firma al hacer clic en el botón que
              indica su almacenamiento. Se reitera que la EMPRESA no tiene
              acceso a esta imagen de firma.
            </li>
          </ul>

          <h2>
            7. <strong>Actualización y Contacto</strong>:
          </h2>
          <ul>
            <li>
              Esta política de privacidad se mantendrá actualizada según se
              realicen cambios en la plataforma que afecten las cláusulas en
              esta descritas. Se podrá contactar con la EMPRESA a la siguiente
              dirección de correo:{" "}
              <a href="mailto:soporte.bitsign@grupobusiness.it">
                soporte.bitsign@grupobusiness.it
              </a>
              .
            </li>
          </ul>
        </div>
      </div>
    );
}
export default PrivacyPolicy