import React, { useState, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Stack, Form } from 'react-bootstrap';
import ModalStepper from  './ModalStepper';
import StepContent from  './StepContent';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { ProcesoContext } from './APIRequest';
import { ParamsContext } from './APIRequest';
import "./FirmarModal.css";
import axios from 'axios';
import $ from 'jquery';
import StepContentCancel from './StepContentCancel';

function FirmarModal({onFinishProcess, showModal, onHide, value}){
  const nombreProceso = value.nameProceso;
  const arrayFirmantes = value.firmantes;
  const { codeCliente, emailUsuario, idProceso } = useContext(ParamsContext);
  const [stateMotivo,setStateMotivo] = useState("");
  const [stateCancel,setStateCancel] = useState(false);
  const [stateFirma,setStateFirma] = useState(null);
  const [loadingState,setLoadingState] = useState(null);
  const [loadedState,setLoadedState] = useState(null);

  const currentUser = arrayFirmantes[0];

  function handleChangeMotivo(event){
    setStateMotivo(event.target.value);
  }

  function postCancelDocument (callback, errorCallback){
    var postObject = {
      "paqueteCliente": codeCliente,
      "IdProceso": idProceso,
      "emailUsuario": emailUsuario,
      "nameStamp": "Cancelado",
      "motivoStamp": stateMotivo
    };
    console.log("Posting data:",postObject);
    axios.post('https://bitsign.azurewebsites.net/api/BitSignUtils/CancelarAnular',postObject)
    .then( response => callback(response) )
    .catch(error => errorCallback(error))
  }

  async function cancelarDocumento(){
    setLoadingState(true);
    setStateFirma('Final');
    if(stateMotivo == ""){
      alert("Debe agregar un motivo para cancelar este documento.");
    }else{
      var newBuffer = await postCancelDocument(async (response) => {
  
        var dataCancel = await response.data
        setLoadingState(false);
        setLoadedState(dataCancel.validated);
        onFinishProcess(dataCancel.validated,dataCancel.base64PDF)
  
        // console.log("Post document response: ",response)
        // console.log("Post document response2: ",dataCancel)
        setStateCancel(true);
      },
      (error) => {
        setLoadingState(false);
        setLoadedState(false);
        console.log("Error:",error);
      });
    }  
  }
  async function modifyPdf() {
    // Fetch an existing PDF document
    const existingPdfBytes = await fetch('/assets/docs/Acta Prueba.pdf').then(res => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    for(var i =0; i<pages.length; i++)
    {
       
        const firstPage = pages[i];

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()
        // console.log("width",width);
        // console.log("height",height);
        // Draw a string of text diagonally across the first page
        firstPage.drawText('¡Cancelado!', {
          x: width/4-50,
          y: height -height / 4,
          size: 90,
          font: helveticaFont,
          color: rgb(0.95, 0.1, 0.1),
          rotate: degrees(-40),
        });
    }


    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save()
    // console.log("Aqui");
    return pdfBytes;
          // Trigger the browser to download the PDF document
    //download(pdfBytes, "pdf-lib_modification_example.pdf", "application/pdf");
  }

  return (
      <>    
        <Modal show={showModal} onHide={onHide} centered>
          <Modal.Header closeButton>
            { !stateCancel &&<Modal.Title>¿Cancelar {`${nombreProceso}`}?</Modal.Title> }
            { stateCancel &&<Modal.Title> {`${nombreProceso}`}</Modal.Title> }
          </Modal.Header>
          <Modal.Body>
            { !stateCancel &&
              <Form.Group controlId="formFile" className="col-10 mx-auto">
                <Form.Label><i>Motivo:</i></Form.Label>
                <Form.Control as="textarea" aria-label="With textarea" value={stateMotivo} onChange={handleChangeMotivo} required/>
              </Form.Group>
            }
            { stateCancel &&
              <StepContentCancel stateFirma={stateFirma} loadingState={loadingState} loadedState={loadedState}/>
            }
          </Modal.Body>
          { !stateCancel && <Modal.Footer>
              <Button className="modal-button" variant="primary" onClick={cancelarDocumento}>
                Sí
              </Button>
              <Button className="modal-button" variant="secondary" onClick={onHide}>
                No
              </Button>
          </Modal.Footer> }
        </Modal>
      </>
    );
}

export default FirmarModal;