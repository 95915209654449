import { React, useState, useEffect, createContext } from 'react';
//import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import FirmadoExterno from './FirmadoExterno';
// import arrayFirmantes from './ArrayFirmantes'
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';

export const ProcesoContext = createContext();
export const ParamsContext = createContext();
// export const ProcesoContext = createContext();
// const FirmantesProceso = init => useContext(FirmantesContext);

export default function APIRequest(props) {

  const requestSigner = props.bs;
  //console.log("req", requestSigner);
  const [proceso,setProceso] = useState({
    base64Documento: "",
    elementosFirma: {nombre: null, fecha: null},
    emailUsuario: "",
    errorValidated: "",
    firmantes: [],
    idProceso: 0,
    nameDocumento: "",
    nameProceso: "",
    paqueteCliente: 0,
    validated: null
  });

  useEffect(()=> {
    const getFirmantes = async ()=>{
      // setLoaded(true)
      const firmantesResponse = await axios.post('https://bitsign.azurewebsites.net/api/BitSignIndependent',
        requestSigner
      ).then((response) => {
        //console.log(response);
        return response.data
      }).catch(error=>{
        var resp = {validated: false}
        //console.error("Error de API Request:",error);
        return resp
      });
      setProceso(firmantesResponse);


    }
    getFirmantes();
  },[requestSigner])

  return (
    <ParamsContext.Provider value={{ idProceso: proceso.idProceso, codeCliente: proceso.paqueteCliente, emailUsuario: proceso.emailUsuario}}>
      { proceso.validated === null &&
        <h3 style={{ textAlign:"center",}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hourglass-split" viewBox="0 0 16 16">
            <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
          </svg> Cargando proceso...
        </h3>
      }
      { proceso.validated === false && 
        <div className='text-center'>
          <h3 style={{ textAlign:"center",}}>
            Error al cargar proceso.
          </h3>
          <Button variant="secondary" className='mt-4' onClick={()=>window.location.reload()}>Volver a cargar</Button>
        </div>
      }
      { proceso.validated && <FirmadoExterno value={proceso} params={{ idProceso: proceso.idProceso, codeCliente: proceso.paqueteCliente, emailUsuario: proceso.emailUsuario}}/> }
    </ParamsContext.Provider>
  );
}
