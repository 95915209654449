import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Page } from 'react-pdf';
import $ from 'jquery';

import { fabric } from "fabric";
import "./PDFReader.css";

import { ProcesoContext } from './APIRequest';

function VerFirmas ({ pageNumber, pageScale, verFirmas, valueFirmantes }){
    const firmantes = valueFirmantes;
    const [fabricCanvas,setCanvas] = useState(null);

    const FirmasArray = useMemo(() =>
        firmantes.filter(firma=> firma!==undefined && !firma.firmaCompletada).map(firmante=>{
            // console.log(firmante)
            var FirmasPorFirmante = [];
            var {nombreFirmante,tipoFirma} = firmante;
            if(typeof firmante.firmas === 'string')
                firmante.firmas = JSON.parse(firmante.firmas)
            firmante.firmas.forEach(firma => {
                var tempFirma = firma;
                tempFirma["nombreFirmante"] = nombreFirmante;
                tempFirma["tipoFirma"] = tipoFirma;
                FirmasPorFirmante.push(tempFirma);
                // console.log(tempFirma)
            });
            return FirmasPorFirmante
        }).flat()
    ,[firmantes]);


    useEffect(() => {
        // console.log("Canvas con pageScale:",fabricCanvas);
        if(fabricCanvas !== null){
            // console.log(fabricCanvas);
            fabricCanvas.setZoom(pageScale);
            // console.log(fabricCanvas.getZoom());
        }
    }, [pageScale,fabricCanvas])
    
    
    function onPageLoadSuccess(page){
        var {pageNumber, height, width} = page
        // console.log("Render success:", {pageNumber, height, width});
        // $("#canvas_"+pageNumber+", #gr-mycanvas_"+pageNumber+" .canvas-container, .upper-canvas.fabric-canvas_"+pageNumber)
        // .attr("width",width)
        // .attr("height",height)
        // .css("width",width)
        // .css("height",height);
        var fabricCanv = new fabric.Canvas($("#canvas_"+pageNumber)[0]);
        fabricCanv.setHeight(height);
        fabricCanv.setWidth(width);
        fabricCanv.renderAll();
        setCanvas(fabricCanv);
        // drawSignatures(fabricCanv);
    }
    
    function drawSignatures(canvas){
        // console.log(canvas);
        // console.log(FirmasArray);
        canvas.remove(...canvas.getObjects())
        FirmasArray.forEach(firma => {
            var { x,y,ancho,alto,numeroHoja,nombreFirmante } = firma;
            // console.log("Datos firma:",x,y,ancho,alto,numeroHoja);
            if(numeroHoja === pageNumber){
                var rect, text, border, group;
                // console.log(firma)
                rect = new fabric.Rect({
                    left:x,
                    top:y,
                    width:ancho,
                    height:alto,
                    // stroke:'#4cae4c',
                    // strokeWidth:3,
                    fill:'white',
                    opacity: 0.5,
                    selectable: false
                });
            
                text = new fabric.Text(String(nombreFirmante), {
                    fontFamily: 'Calibri',
                    fontSize: 10,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    originX: 'center',
                    originY: 'center',
                    left: rect.left + rect.width/2,
                    top: rect.top + rect.height/2,
                    selectable: false
                });
                border = new fabric.Rect({
                    left:x,
                    top:y,
                    width:ancho,
                    height:alto,
                    stroke:'#343a40',
                    strokeWidth:3,
                    fill:'',
                    selectable: false
                    // opacity: 0.5
                });
                group = new fabric.Group([rect, text, border], {
                    angle: 0,
                    lockRotation: true,
                    selectable: false,
                });
                canvas.add(group);
            }
            // Draw the outline of a square
            
        });

      }

      function onRenderPageSuccess(resp){
        // console.log("Render success:",resp);
        var {pageNumber, height, width} = resp
        // $("#canvas_"+pageNumber+", #gr-mycanvas_"+pageNumber+" .canvas-container, .upper-canvas.fabric-canvas_"+pageNumber)
        // .attr("width",width)
        // .attr("height",height)
        // .css("width",width)
        // .css("height",height);
        fabricCanvas.setHeight(height);
        fabricCanvas.setWidth(width);
        fabricCanvas.renderAll();
        drawSignatures(fabricCanvas)
        // console.log(fabricCanvas);
    } 
    return(
            <div id={'gr-mycanvas_'+pageNumber} key={'gr-mycanvas_'+pageNumber} className={'gr-canva mx-auto'}>
                <Page scale={pageScale} className={"mx-auto mb-4"} key={`page_${pageNumber}`} pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} onLoadSuccess={onPageLoadSuccess}
                onRenderSuccess={onRenderPageSuccess}/>
                <canvas id={'canvas_' + pageNumber} key={`canvas_${pageNumber}`} className={`fabric-canvas_${pageNumber} mycanvas mx-auto ${verFirmas?'':'d-none'}` }></canvas>
            </div>
    )
}

export default VerFirmas;