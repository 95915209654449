import { React, useState, useEffect, useContext, createContext } from 'react';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
import { Button, ToggleButton, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { BsZoomIn, BsZoomOut } from 'react-icons/bs';
import FirmarModal from './FirmarModal';
import FirmantesModal from './FirmantesModal';
import CancelarModal from './CancelarModal';
import PDFReader from './PDFReader';
// import arrayFirmantes from './ArrayFirmantes'
import "./FirmadoExterno.css";

import 'bootstrap/dist/css/bootstrap.min.css';
//import logo from '../LogoBITSign.png'; // gives image path

import { ParamsContext } from './APIRequest';
// export const FileContext = createContext();
export const UserContext = createContext();

export default function FirmadoExterno(props) {
  const { nameProceso, stateProceso, firmantes, base64Documento, nameDocumento, elementosFirma } = props.value;
  
  const { emailUsuario } = useContext(ParamsContext);

  const [verFirmas, setVerFirmas] = useState(false);
  const [firmantesUpdated,setFirmantesUpdated] = useState(firmantes)
  const [pageScale, setPageScale] = useState(1.2);
  const [disableZoomOut, setDisableOut] = useState(false);
  const [disableZoomIn, setDisableIn] = useState(false);
  const [showModalFirma, setShowModalFirma] = useState(false);
  const [showModalFirmantes, setShowModalFirmantes] = useState(false);
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const [estadoProceso, setEstadoProceso] = useState(stateProceso)
  const [signedDocument,setUpdatedDocument] = useState(base64Documento);
  const [currentUser,setCurrentUser] = useState(false);
  const [lastUser,setLastUser] = useState(false);
  const [errorState,setErrorState] = useState('false');

  const processUser = firmantes.find(firmante=> firmante.emailFirmante === emailUsuario)
  //console.log("eu",emailUsuario)
  //console.log("a",processUser);
  //console.log("b",props.value);
  //console.log("c",firmantes);
// console.log("Current User:",currentUser);
  const [procesoCompletado,setDisableDescarga] = useState(!firmantes.some(firmante => 
    !firmante.firmaCompletada)
  );

  useEffect(()=> {
    if (processUser !== undefined){
      setCurrentUser(processUser.boolNotificado && !processUser.firmaCompletada)
      var isUserLast = firmantes[firmantes.length - 1].emailFirmante === processUser.emailFirmante;
      setLastUser(isUserLast)
    }
  // console.log("Current User:",currentUser);
  },[processUser,firmantes]);

  useEffect(()=>{
    // console.log("Current user:",processUser);
  },[processUser]);

  useEffect(() => {
    // console.log("PageScale:",pageScale);
    if (pageScale >= 1.728){
      setDisableIn(true);
      // console.log("Maximo escalado",pageScale);
    }
    else if (pageScale <= 0.694){
      setDisableOut(true);
      // console.log("Minimo escalado",pageScale);
    }
    else{
      setDisableIn(false);
      setDisableOut(false);
      // console.log("Escalado",pageScale);
    }
  },[pageScale]);

  function zoomIn() {
    setPageScale(Math.round((pageScale*(1.2))*1000)/1000);
  }
  
  function zoomOut() {
    setPageScale(Math.round((pageScale/(1.2))*1000)/1000);
  }

  function openModalFirma() {
    if(processUser.tipoFirma === 'Criptográfica')
      {
        window.location = processUser.urlFirmaCriptograficaPeru;
      }
    else
      setShowModalFirma(true);
  }

  function closeModalFirma(callBackOnError) {
    setShowModalFirma(false);
    if(errorState !== 'error'){
      callBackOnError();
    }
  }
  function openModalFirmantes() {
    setShowModalFirmantes(true);
  }

  function closeModalFirmantes() {
    setShowModalFirmantes(false);
  }
  function openModalCancelar() {
    setShowModalCancelar(true);
  }

  function closeModalCancelar() {
    setShowModalCancelar(false);
  }

  function finishProcess(response,document) {
    if(response){
      setEstadoProceso('Firmado');
      setUpdatedDocument(document);
      setFirmantesUpdated(firmantes.map((firmante,firmanteIndex)=>{
        if (firmante === processUser){
          return {
            ...firmante,
            firmaCompletada: true
          }
        }
        return firmante;
      }));
    }
      // setSignedResponse('error');
    else{
      setErrorState('error');
    }
  }

  function downloadDocument(url, fileName) {
    if(estadoProceso === 'En Proceso'){
      alert("El proceso debe concluir antes de poder descargar este documento.");
    }
    else {
      const linkSource = `data:application/pdf;base64,${url}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

  return (
        <Container fluid className="p-0">
          <Navbar className="ps-4 pe-2 py-1" sticky='top' bg="light" expand="lg">
            <Container fluid>
              <Navbar.Brand className="me-3" href="#logo">
                <Image
                  alt=""
                  src="https://azfstorage593.z13.web.core.windows.net/BitSignLogo.png"
                  // width="30"
                  height="50px"
                  className="d-inline-block align-top"
                />{' '}</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav navbarScroll className="navbar-nav nav-fill w-100">
                  <Navbar.Text className="w-100">
                    <ButtonToolbar>
                      <Button className="mx-auto" size="sm" variant="outline-secondary" onClick={openModalFirma} style={{width: 100}} disabled={!currentUser || estadoProceso !== 'En Proceso'}>Firmar</Button>
                      <Button className="mx-auto" size="sm" variant="outline-secondary" onClick={openModalCancelar} style={{width: 100}} disabled={!currentUser || estadoProceso !== 'En Proceso'}>Cancelar</Button>
                      <Button className="mx-auto" size="sm" variant="outline-secondary" onClick={openModalFirmantes} style={{width: 100}}>Firmantes</Button>
                      <ToggleButton
                        className="mx-auto"
                        id="toggle-check"
                        type="checkbox"
                        size="sm"
                        variant="outline-secondary"
                        style={{width: 100}}
                        checked={verFirmas}
                        value="1"
                        onChange={(e) => setVerFirmas(e.currentTarget.checked)}
                        disabled={processUser.tipoFirmado === 'Anexo' }
                      >
                        Ver Firmas
                      </ToggleButton>
                      <ButtonGroup className="mx-auto" style={{width: 100}}>
                        <Button size="sm" variant="outline-secondary" disabled={disableZoomIn} onClick={zoomIn}><BsZoomIn style={{verticalAlign:"initial"}}/></Button>
                        <Button size="sm" variant="outline-secondary" disabled={disableZoomOut} onClick={zoomOut}><BsZoomOut style={{verticalAlign:"initial"}}/></Button>
                      </ButtonGroup> 
                      <Button className={`mx-auto ${estadoProceso !== 'En Proceso' ?'':'d-none'}`} size="sm" variant="outline-secondary" disabled={estadoProceso === 'En Proceso'} 
                        onClick={()=>downloadDocument(signedDocument,
                          `${nameProceso} - ${new Date().toLocaleString("es-US", { year: "numeric", month: '2-digit', day: '2-digit'})}`)} 
                        style={{width: 100}}>Descargar</Button>
                    </ButtonToolbar>  
                    {' '}
                  </Navbar.Text>
                  {/* <Nav.Link className="h5 mx-4" >Regresar{' '}</Nav.Link> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div id="cuerpo" className="row justify-content text-center pt-3 align-items-center" style={{width:"initial", margin: 0, opacity: 0.9, backgroundColor:"#a5a5a5"}}>
            <div id="docVis">
              <PDFReader arrayFirmantes={firmantes} scale={pageScale} verFirmas={verFirmas} docData={{base64:signedDocument, name:nameDocumento}}/>
            </div>
            <div id="emailwidth" className='hiddenElements'></div>
            <div id="fechaActualwidth" className='hiddenElements'></div>
            <div id="nombrewidth" className='hiddenElements'></div>
            <div id="inicialeswidth" className='hiddenElements'></div>
          </div>
          <FirmarModal onHide={(callBackOnError)=>closeModalFirma(callBackOnError)} showModal={showModalFirma} processUser={processUser} elementosFirma={elementosFirma} onFinishProcess={finishProcess} estadoProceso={estadoProceso} docData={{base64:signedDocument, name:nameDocumento}} lastUser={lastUser} value={props.value}/>
          <CancelarModal onHide={closeModalCancelar} showModal={showModalCancelar} value={props.value} onFinishProcess={finishProcess}/>
          <FirmantesModal onHide={closeModalFirmantes} showModal={showModalFirmantes} valueFirmantes={firmantesUpdated}/>
        </Container>
  );
}
