import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner, Alert } from 'react-bootstrap';
import { Stack } from 'react-bootstrap';
// import $ from 'jquery';

function StepContentCancel({ stateFirma, loadingState, loadedState }){

    return (
        <>
            { loadingState ?
                <Stack direction="horizontal" gap={2} className={`justify-content-center ${stateFirma === 'Final' ? '' : 'd-none'}`}>
                    <Spinner variant="secondary" size="sm" animation="border">
                        <span className="visually-hidden">Cargando</span>
                    </Spinner> <span className=''>Cargando</span>
                </Stack>:
                loadedState ? 
                    <div className={`${stateFirma === 'Final' ? '' : 'd-none'}`}>
                        <Alert variant="success">
                            <Alert.Heading className='text-center'>Cancelado Exitosamente!</Alert.Heading>
                        </Alert>
                    </div>:
                    <div className={`${stateFirma === 'Final' ? '' : 'd-none'}`}>
                        <Alert variant="danger">
                            <Alert.Heading className='text-center'>Error al Cancelar</Alert.Heading>
                        </Alert>
                    </div>
            }
        </>
    )
}
export default StepContentCancel;