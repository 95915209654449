import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Text, FormSelect, Form, Spinner, Alert } from 'react-bootstrap';
import { Stack } from 'react-bootstrap';
import "./StepContent.css";
import SignatureCanvas from 'react-signature-canvas'
import { fabric } from "fabric";
// import $ from 'jquery';

function StepContent({ stateFirma, onCanvasChange, onCanvasSumChange, setTypeFirma, updateImagenFirma, updateImagenSumilla, enableNext, loadingState, loadedState }){
    const MAX_WIDHT = 300;
    const MAX_HEIGHT = 150;

    const canvasFile = useRef();
    const canvasPad = useRef();
    const canvasText = useRef();
    const canvasFileSum = useRef();
    const canvasPadSum = useRef();
    const canvasTextSum = useRef();
    const timer = useRef(null)
    const [selectValueFirma,setSelectValueFirma] = useState("Digital");
    const [selectValueSumilla,setSelectValueSumilla] = useState("Digital");
    const [inputTexto,setInputTexto] = useState("");
    const [signOnCanvas,setSignOnCanvas] = useState(false);
    const [signOnImage,setSignOnImage] = useState(false);
    const [sumOnCanvas,setSumOnCanvas] = useState(false);
    const [sumOnImage,setSumOnImage] = useState(false);

    // useEffect(()=>{
    //         onCanvasChange(canvasFile.current);
    //         onCanvasSumChange(canvasFileSum.current);
    //         // console.log("SignPad:",canvasPad);
    //         // console.log(canvasFileSum);

    // },[])

    useEffect(() => {
      console.log("Cambio de estado")
      var isNextEnabled = stateFirma === 'Firma' ? 
        selectValueFirma === 'Imagen' ?
            signOnImage:
            // selectValueFirma === 'Texto?
                // signOnText:
            signOnCanvas:
        selectValueFirma === 'Imagen' ?
            sumOnImage:
            sumOnCanvas;
  
      enableNext(isNextEnabled)
    }, [stateFirma])
    

    useEffect(() => {
        if(selectValueFirma === 'Imagen'){
            onCanvasChange(canvasFile.current);
            enableNext(signOnImage);
        }
        else if(selectValueFirma === 'Digital'){
            // console.log(canvasPad.current);
            onCanvasChange(canvasPad.current.getCanvas());
            enableNext(signOnCanvas);
        }
        else if(selectValueFirma === 'Texto'){
            // console.log(canvasPad.current);
            onCanvasChange(canvasText.current);
        }
        setTypeFirma(selectValueFirma);

    }, [selectValueFirma])
    
    useEffect(() => {
        if(selectValueSumilla === 'Imagen'){
            onCanvasSumChange(canvasFileSum.current);
            enableNext(sumOnImage);
        }
        else if(selectValueSumilla === 'Digital'){
            // console.log(canvasPadSum.current);
            onCanvasSumChange(canvasPadSum.current.getCanvas());
            enableNext(sumOnCanvas);
        }
    }, [selectValueSumilla])
      
    useEffect(() => {
        // console.log(inputTexto);
        clearTimeout(timer.current)
        timer.current = setTimeout(function(){
            var canvasTexto = canvasText.current;
            canvasTexto.width=322;
            canvasTexto.height=107;
            fabric.devicePixelRatio = 2;
            var firmaCanvas = new fabric.StaticCanvas(canvasTexto.id);
            var firmaText = new fabric.Textbox(inputTexto, {
                fontFamily: 'Edwardian Script ITC',
                fontSize: 34,
                fontWeight: 400,
                lineHeight: 0.8,
                textAlign: 'center',
                originX: 'center',
                originY: 'center',
                left: firmaCanvas.width/2,
                top: firmaCanvas.height/2,
                // width: firmaCanvas.width-10,
                selectable:false,
            })
            // console.log(firmaText.toDataURL({top:-5,left:-5,height:firmaText.height+15,width:firmaText.width+15}));
            // while (firmaText.width > firmaCanvas.width) {
            //     firmaText.setWidth(firmaText.width -= 10);
            // }

            // firmaText.set({width: firmaText.getWidth()})
            // console.log(firmaText);
            
            firmaCanvas.add(firmaText);
            firmaCanvas.renderAll();
            // console.log(firmaCanvas);
            var cropping = {
                multiplier:1.7,
                top: firmaText.height>=60?-5:-40+firmaText.height-5,
                left: -15,
                height: firmaText.height>=60?firmaText.height+20:60,
                width:firmaText.width+30
                // left: (firmaCanvas.width/2 - firmaText.width/2),
            }
            // console.log(cropping);

            var firmaTextoURL = firmaText.toDataURL(cropping);
            updateImagenFirma(firmaTextoURL)
            // var image = new Image();
            // image.src = firmaTextoURL;
            // var w = window.open("");
            // w.document.write(image.outerHTML);
            // console.log(firmaTextoURL);
            // if(inputTexto.value==''){
            //     blankText = document.getElementById("canvasTexto").toDataURL();
            // }
            // $('#btnMdlNext').prop('disabled',false);
        },1000)
    }, [inputTexto])
  
    function undoStroke(canvas){
        var data = canvas.toData();
        if(data) {
            data.pop();
            canvas.fromData(data);
            if(data.length===0){
                stateFirma === 'Firma' ? setSignOnCanvas(false) : setSumOnCanvas(false);
                enableNext(false);
            }
        }
    }

    function updateSignature(canvas,updateImage){
        // console.log(canvas.toDataURL('image/png'))
        var sigImage = canvas.toDataURL('image/png').replace('data:image/png;base64,', '')
        // console.log(sigImage);
        updateImage(sigImage);
    }

    function renderTextImage(textInput){
        setInputTexto(textInput)
    }

    function changeImage(e, canvasElement, onFinish, updateImagen) {
        // console.log(target);
        var target= e.target;
        
        if(target.files.length !== 0){
            const imagePromise = new Promise((resolve,reject)=>{
                enableNext(false);
                var file = target.files[0];
                var fileType = file.type;
                // console.log(target.files);
                var canvas = canvasElement.current;
                var ctx = canvas.getContext('2d');

                if (fileType !== "image/jpeg" && fileType !== "image/png" && fileType !== "image/jpg" )
                {
                    alert('Debe ingresar una imagen en formato JPG o PNG.');
                    target.value = '';
                    //limpiar canvas
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    return false;
                }

                var url = URL.createObjectURL(file);
                var img = new Image();
                img.onload = function () 
                {
                    var width = img.width,
                        height = img.height;
    
                    //resize
                    if (width > height) {
                        if (width > MAX_WIDHT) {
                            height *= MAX_WIDHT / width;
                            width = MAX_WIDHT;
                        }
                    }
                    else{
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
    
                    ctx.drawImage(img, 0, 0, width, height);
                    resolve()
                };
                img.src = url;
                // setTimeout(()=>{
                // },1000)   
            })
            imagePromise.then(()=>{
                updateSignature(canvasElement.current,updateImagen);
                onFinish(canvasElement.current);
                stateFirma === 'Firma' ? setSignOnImage(true) : setSumOnImage(true);
                enableNext(true);
            })

        }
    }

    return (
        <>
            {
                <Stack gap={2} className={`col-md-8 text-center mx-auto${stateFirma === 'Firma' ? '' : ' d-none'}`}>
                    <h5 className="mx-auto">FIRMA</h5>
                    <FormSelect size="sm" taria-label="Default select example" onChange={(e)=>setSelectValueFirma(e.target.value)}>
                        <option value="Digital">Digital</option>
                        <option value="Imagen">Imagen</option>
                        {/* <option value="Texto">Texto</option> */}
                    </FormSelect>

                    <div id="divSigPad" className={selectValueFirma === 'Digital'? '' : 'd-none'}>
                        <SignatureCanvas className="signatureCanvas" penColor='#145394' minWidth={1.0} maxWidth={1.0} canvasProps={{width: 300, height: 150, className: 'sigCanvas'}} ref={canvasPad} 
                            onEnd={() => {
                                updateSignature(canvasPad.current,updateImagenFirma);
                                setSignOnCanvas(true);
                                enableNext(true);
                            }}
                        />
                        <Stack gap={2} direction="horizontal" className={"mx-auto"}>
                            <Button className="ms-auto" variant="secondary" size="sm" style={{width: 80}}
                                onClick={()=>{
                                    canvasPad.current.clear();
                                    updateSignature(canvasPad.current,updateImagenFirma);
                                    setSignOnCanvas(false);
                                    enableNext(false);
                                }}
                            >
                                Borrar
                            </Button>
                            <Button variant="secondary" size="sm" style={{width: 80}}
                                onClick={()=>{
                                    undoStroke(canvasPad.current);
                                    updateSignature(canvasPad.current,updateImagenFirma);
                                }} 
                            >
                                Deshacer
                            </Button>
                        </Stack >
                    </div>
                    <div id="divSigFile" className={selectValueFirma === 'Imagen'? '' : 'd-none'}>
                        <canvas id='canvasFile' width="300" height="150" className="mx-auto signatureCanvas" ref={canvasFile}></canvas>
                        <Form.Group controlId="formFile" className="mb-3 mx-auto">
                            <Form.Label>Subir imagen PNG/JPG de la firma</Form.Label>
                            <Form.Control type="file" accept='.png,.jpg' onChange={(e) => changeImage(e, canvasFile, onCanvasChange, updateImagenFirma)}/>
                        </Form.Group>      
                    </div>
                    <div id="divSigText" className={selectValueFirma === 'Texto'? '' : 'd-none'}>
                        <label className="radio-inline" >Ingrese su firma: 
                        <Form.Control size="sm" type="text" name="firmaTexto" id="inputTexto" maxLength="25" style={{margin:"0px auto"}} onChange={e=>renderTextImage(e.target.value)}/></label><br/><br/>
                        <div id="canvas-wrapper">
                            <canvas id="canvasTexto" className="firmaTexto" width="320" height="105" style={{border:"1px solid #000"}} ref={canvasText}></canvas>
                        </div>
                        {/* <SignatureCanvas className="signatureCanvas" penColor='#145394' minWidth={1.0} maxWidth={1.0} onEnd={() => updateSignature(canvasPad.current,updateImagenFirma)} canvasProps={{width: 300, height: 150, className: 'sigCanvas'}} ref={canvasPad} /> */}
                        {/* <Stack gap={2} direction="horizontal" className={"mx-auto"}>
                        
                        </Stack > */}
                    </div>
                </Stack >
            }
            {
                <Stack gap={2} className={`col-md-8 mx-auto text-center ${stateFirma === 'Sumilla'?'':'d-none'}`}>
                    <h5 className="mx-auto">SUMILLA</h5>
                    <FormSelect size="sm" taria-label="Default select example" onChange={(e)=>setSelectValueSumilla(e.target.value)}>
                        <option value="Digital">Digital</option>
                        <option value="Imagen">Imagen</option>
                    </FormSelect>

                    <div id="divSumPad" className={selectValueSumilla === 'Digital'? '' : 'd-none'}>
                        <SignatureCanvas className="signatureCanvas" penColor='#145394' minWidth={1.0} maxWidth={1.0} canvasProps={{width: 170, height: 100, className: 'sumCanvas'}} ref={canvasPadSum} 
                            onEnd={() => {
                                updateSignature(canvasPadSum.current,updateImagenSumilla)
                                setSumOnCanvas(true);
                                enableNext(true);
                            }} 
                        />
                        <Stack gap={2} direction="horizontal" className={"mx-auto"}>
                            <Button className="ms-auto" variant="secondary" size="sm" style={{width: 80}}
                                onClick={()=>{
                                    canvasPadSum.current.clear();
                                    updateSignature(canvasPadSum.current,updateImagenSumilla);
                                    setSumOnCanvas(false);
                                    enableNext(false);
                                }}
                            >
                                Borrar
                            </Button>
                            <Button className="me-5" variant="secondary" size="sm" style={{width: 80}}
                                onClick={()=>{
                                    undoStroke(canvasPadSum.current);
                                    updateSignature(canvasPadSum.current,updateImagenSumilla);
                                }} 
                            >
                                Deshacer
                            </Button>
                        </Stack >
                    </div>
                    <div id="divSumFile" className={selectValueSumilla === 'Imagen'? '' : 'd-none'}>
                        <canvas id='canvasFileSum' width="170" height="100" className="mx-auto" style={{border:"1px solid #000000"}} ref={canvasFileSum}></canvas>
                        <Form.Group controlId="formFile" className="mb-3 mx-auto">
                            <Form.Label>Subir imagen PNG/JPG de la firma</Form.Label>
                            <Form.Control type="file" accept='.png,.jpg' onChange={(e) => changeImage(e, canvasFileSum, onCanvasSumChange, updateImagenSumilla)}/>
                        </Form.Group>
                    </div>
                </Stack >
            }
            { loadingState ?
                <Stack direction="horizontal" gap={2} className={`justify-content-center ${stateFirma === 'Final' ? '' : 'd-none'}`}>
                    <Spinner variant="secondary" size="sm" animation="border">
                        <span className="visually-hidden">Cargando</span>
                    </Spinner> <span className=''>Cargando</span>
                </Stack>:
                loadedState ? 
                    <div className={`${stateFirma === 'Final' ? '' : 'd-none'}`}>
                        <Alert variant="success">
                            <Alert.Heading className='text-center'>Firmado Exitosamente!</Alert.Heading>
                        </Alert>
                    </div>:
                    <div className={`${stateFirma === 'Final' ? '' : 'd-none'}`}>
                        <Alert variant="danger">
                            <Alert.Heading className='text-center'>Error al Firmar</Alert.Heading>
                        </Alert>
                    </div>
            }
        </>
    )
}
export default StepContent;