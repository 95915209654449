import React, { useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal } from 'react-bootstrap';
import { BsCheck2, BsX } from 'react-icons/bs';
import { ProcesoContext } from './APIRequest';
function FirmantesModal(props){
  const arrayFirmantes = props.valueFirmantes

  return (
    <>  
      <Modal show={props.showModal} onHide={props.onHide} size="sm" centered >
        <Modal.Header closeButton>
          <Modal.Title>Lista de Firmantes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {arrayFirmantes.some(item=>item.firmaCompletada)?<h5>Firmantes Procesados</h5>:''}
          {arrayFirmantes.map((element,index) => {
            return(
              element.firmaCompletada&&<div key={'firmante-'+index+1}>
                <span key={"nombre-firmante-"+index+1}>{element.nombreFirmante}</span>
                <BsCheck2 className='text-success float-end me-2'/>
              </div>
            )
          })}
          {arrayFirmantes.some(item=>!item.firmaCompletada)?<h5>Firmantes Faltantes</h5>:''}
          {arrayFirmantes.map((element,index) => {
            return(
              !element.firmaCompletada&&<div key={'firmante-'+index+1}>
                <span key={"nombre-firmante-"+index+1}>{element.nombreFirmante}</span>
                <BsX className='text-danger float-end me-2'/>
                </div>
            )
          })}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FirmantesModal;