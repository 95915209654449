import React, {useState, useEffect} from "react";
import './BitSignDesk.css';
import { Button, Image } from "react-bootstrap";

const BigPrimeDesk = () => {

    const [bsLink, setbsLink] = useState("");

    useEffect(() => {
        const bslink = window.location.search.split('?link=')[1];
        //console.log("queryString", bstoken);
        if(bslink != null){
            setbsLink(bslink)
            //document.getElementById("fcsigner").setAttribute('href', bslink);
        }
    },[])

    return(
        <div className="BigPrimeDesk">
            <div className="init">
                <Image src="https://azfstorage593.z13.web.core.windows.net/BitSignLogo.png" className="logo" />
                <br/>
                <br/>
                <p>Estimado Usuario, si el Aplicativo de Escritorio no se ejecuta automáticamente, por favor acceder con el siguiente botón:</p>
                <Button className="btn btn-dark" id='fcsigner' href={bsLink}>
                    Firma Criptográfica
                </Button>
                <br/>
                <br/>
                <p>Instalador Aplicativo de Escritorio:</p>
                <Button className="btn btn-dark" id='fcinstaller' href="https://beducacion.sharepoint.com/:u:/s/BigPrimePeru/EWDfEgYcspxKu8jRax7EsI4BR4NtQ9sy4ZcktReCaf5BQw?e=bXNmLR&download=1">
                    Descargar
                </Button>
                <br/>                
                <br/>
                <a href='/PrivacyPolicy'>Política de Privacidad</a>
            </div>
        </div>
    )
}
export default BigPrimeDesk